<template>
    <v-container>
        <Header
            :registryStatus="getPartStatuses"
            :recordIndex="getPartDetails.index"
            :registryTitle="getPartsRegistry.name"
            :registryUrl="'parts'"
            :actualStatus="getPartDetails.status"
            @status-changed="changeStatus"
            :avatarData="getPartDetails.createdBy"
        />
        <Main class="p-relative">
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('parts:editionPart')"
                    :open="open"
                    :height="'550'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editPart()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('parts:editPart') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>
<script>
import store from './../../../store/index'
import Information from './../../../components//Registries/Parts/Details/Information'
import Buttons from './../../../components//Registries/Parts/Modal/Buttons'
import AEContent from './../../../components//Registries/Parts/Modal/Content'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: { Information, Buttons, AEContent },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        ...mapGetters([
            'getPartDetails',
            'getPartStatuses',
            'getPartsRegistry',
        ]),
    },
    methods: {
        ...mapMutations(['clearPartsErrors', 'clearPartsModal', 'setPartModal']),
        ...mapActions(['updatePartStatus', 'fetchRegistriesTable']),
        async editPart() {
            this.open = true
            this.fetchRegistriesTable({
                select: 'icon url',
                where: { url: 'models' },
            })
            this.setPartModal()
        },
        ...mapActions(['fetchPart']),
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async changeStatus(status) {
            await this.updatePartStatus({
                status,
                id: this.getPartDetails._id,
            })
            this.closeModal
        },
        closeModal() {
            this.open = false
            this.clearPartsErrors()
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchPart', { id: to.params.id, next })
    },
}
</script>
