<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step :rules="[]" editable step="1">{{
                                $t('parts:informations')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="
                                                getPartModal.manufacturerIndex
                                            "
                                            :error-messages="
                                                getPartError.stepOne.fields
                                                    .manufacturerIndex
                                            "
                                            :label="
                                                $t('parts:manufacturerIndex')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="getPartModal.manufacturer"
                                            :label="$t('parts:manufacturer')"
                                            :items="manufacturer"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="getPartModal.name"
                                            :label="$t('parts:name')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select
                                            v-model="getPartModal.substitute"
                                            :items="options"
                                            :label="$t('parts:substitute')"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="getPartModal.partCondition"
                                            :label="$t('parts:lifetime')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <money-field
                                            v-model="getPartModal.price"
                                            :label="$t('parts:price')"
                                        >
                                        </money-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="getPartModal.modelSeries"
                                            :label="$t('parts:modelSeries')"
                                            :items="modelSeries"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-combobox
                                            v-model="getPartModal.model"
                                            :error-messages="
                                                getPartError.stepOne.fields
                                                    .model
                                            "
                                            :label="$t('parts:model')"
                                            :hint="`${$t(
                                                'global:startWriting'
                                            )}`"
                                            @keyup="
                                                setModels($event.target.value)
                                            "
                                            return-object
                                            item-text="model"
                                            item-value="_id"
                                            :items="getModelsInput.items"
                                            ><template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                {{ data.item.model }}
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                {{ data.item.model }}
                                            </template>
                                            <template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >{{
                                                        getRegistriesTable
                                                            .items[0].icon
                                                    }}</v-icon
                                                >
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="getPartModal.comment"
                                            :label="$t('parts:description')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            step: 1,
            editable: true,
            options: [
                { text: this.$t('global:yes'), value: true },
                { text: this.$t('global:no'), value: false },
            ],
        }
    },
    methods: {
        ...mapMutations(['setModelsInput']),
        ...mapActions(['fetchModelsInputs']),
        setModels(search) {
            if (search.length >= 3) {
                this.setModelsInput({ search })
                this.fetchModelsInputs()
            } else {
                this.getModelsInput.items = []
            }
        },
    },

    computed: {
        ...mapGetters([
            'getPartModal',
            'getRegistryDictionaries',
            'getPartError',
            'getModelsInput',
            'getRegistriesTable',
        ]),

        manufacturer: {
            get() {
                if (this.getRegistryDictionaries.manufacturer)
                    return this.getRegistryDictionaries.manufacturer.sort(
                        (a, b) => (a.field > b.field ? 1 : -1)
                    )
                else return []
            },
        },
        modelSeries: {
            get() {
                if (this.getRegistryDictionaries.modelSeries)
                    return this.getRegistryDictionaries.modelSeries.sort(
                        (a, b) => (a.field > b.field ? 1 : -1)
                    )
                else return []
            },
        },
    },
}
</script>
