<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0"
                        >
                            {{ getPartDetails.manufacturerIndex }}
                        </p>
                        <span class="mb-5">{{
                            getCarDetails.registrationNumber
                        }}</span>
                        <span
                            ><b>{{ `${$t('parts:manufacturer')}` }}:</b>
                            {{ $get(getPartDetails, 'manufacturer', '') }}
                        </span>
                        <span class="mt-5"
                            ><b>{{ `${$t('parts:name')}` }}:</b>
                            {{ $get(getPartDetails, 'name', '') }}</span
                        >
                        <span
                            ><b>{{ `${$t('parts:price')}` }}:</b>
                            {{ `${getPartDetails.price.toFixed(2)} PLN` }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('parts:informations')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6"
                        ><v-text-field
                            :value="getPartDetails.manufacturerIndex"
                            readonly
                            :label="$t('parts:manufacturerIndex')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols=""
                        ><v-text-field
                            :value="getPartDetails.manufacturer"
                            readonly
                            :label="$t('parts:manufacturer')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            :value="getPartDetails.name"
                            readonly
                            :label="$t('parts:name')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            :value="$t(`parts:${getPartDetails.substitute}`)"
                            readonly
                            :label="$t('parts:substitute')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            :value="getPartDetails.partCondition"
                            readonly
                            :label="$t('parts:lifetime')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <money-field
                            :disabled="false"
                            :readonly="true"
                            :label="$t('parts:price')"
                            :value="getPartDetails.price"
                        ></money-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            :value="getPartDetails.modelSeries"
                            readonly
                            :label="$t('parts:modelSeries')"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="6">
                        <v-text-field
                            :value="$get(getPartDetails.model, 'model', '')"
                            readonly
                            :label="$t('parts:model')"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            :value="getPartDetails.comment"
                            readonly
                            no-resize
                            rows="1"
                            auto-grow
                            :label="$t('parts:description')"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getCarDetails', 'getPartDetails']),
    },
}
</script>
<style lang="sass" scoped>
.money-value
    font-size: 16px !important
    color: rgba(0, 0, 0, 0.87) !important
</style>
