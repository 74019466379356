var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0"},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('parts:informations')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"error-messages":_vm.getPartError.stepOne.fields
                                                .manufacturerIndex,"label":_vm.$t('parts:manufacturerIndex')},model:{value:(
                                            _vm.getPartModal.manufacturerIndex
                                        ),callback:function ($$v) {_vm.$set(_vm.getPartModal, "manufacturerIndex", $$v)},expression:"\n                                            getPartModal.manufacturerIndex\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.$t('parts:manufacturer'),"items":_vm.manufacturer,"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getPartModal.manufacturer),callback:function ($$v) {_vm.$set(_vm.getPartModal, "manufacturer", $$v)},expression:"getPartModal.manufacturer"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('parts:name')},model:{value:(_vm.getPartModal.name),callback:function ($$v) {_vm.$set(_vm.getPartModal, "name", $$v)},expression:"getPartModal.name"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.options,"label":_vm.$t('parts:substitute')},model:{value:(_vm.getPartModal.substitute),callback:function ($$v) {_vm.$set(_vm.getPartModal, "substitute", $$v)},expression:"getPartModal.substitute"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('parts:lifetime')},model:{value:(_vm.getPartModal.partCondition),callback:function ($$v) {_vm.$set(_vm.getPartModal, "partCondition", $$v)},expression:"getPartModal.partCondition"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('money-field',{attrs:{"label":_vm.$t('parts:price')},model:{value:(_vm.getPartModal.price),callback:function ($$v) {_vm.$set(_vm.getPartModal, "price", $$v)},expression:"getPartModal.price"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.$t('parts:modelSeries'),"items":_vm.modelSeries,"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getPartModal.modelSeries),callback:function ($$v) {_vm.$set(_vm.getPartModal, "modelSeries", $$v)},expression:"getPartModal.modelSeries"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"error-messages":_vm.getPartError.stepOne.fields
                                                .model,"label":_vm.$t('parts:model'),"hint":`${_vm.$t(
                                            'global:startWriting'
                                        )}`,"return-object":"","item-text":"model","item-value":"_id","items":_vm.getModelsInput.items},on:{"keyup":function($event){return _vm.setModels($event.target.value)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.model)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.model)+" ")]}},{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v(_vm._s(_vm.getRegistriesTable .items[0].icon))])]},proxy:true}]),model:{value:(_vm.getPartModal.model),callback:function ($$v) {_vm.$set(_vm.getPartModal, "model", $$v)},expression:"getPartModal.model"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('parts:description')},model:{value:(_vm.getPartModal.comment),callback:function ($$v) {_vm.$set(_vm.getPartModal, "comment", $$v)},expression:"getPartModal.comment"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }